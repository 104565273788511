@import-normalize;

* {
  font-family: "Noto Sans JP";
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  line-height: 60px;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 5px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  white-space: nowrap;
}

label {
  margin-right: 0.5rem;
}

pre {
  max-width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  user-select: all;
  border: solid 1px gray;
  padding: 0.5rem 0.5rem;
}

input:read-only {
  background: rgb(229, 229, 229);
}
