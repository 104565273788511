.button {
  height: 32px;
  min-width: 6rem;
  border-radius: 94px;

  box-sizing: border-box;

  padding-left: 2rem;
  padding-right: 2rem;

  font-family: "Noto Sans JP";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;

  border-width: 0;
  border-style: none;

  margin-right: 0.1rem;
  margin-left: 0.1rem;

  overflow: hidden;
}

.button:hover {
  cursor: pointer;
  transition: ease-in;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
  box-shadow: 0px 0px 8px #a5a6f6;
}

.button:active {
  transition: ease-in;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5) 0 0);
  box-shadow: 0px 0px 4px #a5a6f6;
}

.primary-button {
  background: #5d5fef;
  box-shadow: 0px 0px 16px #a5a6f6;

  color: white;
}

.secondary-button {
  background: #323245;
  box-shadow: 0px 0px 16px #a5a6f6;

  color: white;
}

.gray-button {
  background: #d0d1e1;
  box-shadow: 0px 0px 16px #c4c4c4;

  color: black;
}

.light-button {
  background: #a7a8f2;
  box-shadow: 0px 0px 16px #a5a6f6;

  color: white;
}

.outlined-button {
  background: transparent;
  border: #5d5fef solid 1px;
  box-shadow: 0px 0px 16px #a5a6f6;
  color: #5d5fef;
}

.outlined-button:hover {
  cursor: pointer;
  border: #5d5fef solid 1px;
  box-shadow: 0px 0px 8px #a5a6f6;
  transition: ease-in;
  background-image: none;
}

.outlined-button:active {
  transition: ease-in;
  box-shadow: 0px 0px 4px #a5a6f6;
  background-image: none;
}

.text-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: black;

  padding: 0 0 0 0;
  min-width: inherit;
}

.text-button:hover {
  cursor: pointer;
  border: none;
  box-shadow: none;
  transition: none;
  background-image: initial;

  font-weight: 700;
}

.text-button:active {
  cursor: pointer;
  border: none;
  box-shadow: none;
  transition: none;
  background-image: none;

  font-weight: 900;
}

.button:disabled {
  box-shadow: none;
  filter: brightness(0.7);
}

.button:hover:disabled {
  cursor: default;
  background-image: none;
}
