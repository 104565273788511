.wrapper {
  display: flex;
  min-height: 100vh;
}

.wrapper-left {
  flex: 50%;
  min-height: 100%;
  min-width: 20rem;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 10vh;
  padding-bottom: 10vh;

  background-color: white;
}

.wrapper-right {
  flex: 50%;
  min-height: 100%;

  box-sizing: border-box;

  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 15vh;
  padding-bottom: 15vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-origin: content-box;
}
