.wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  padding-left: 220px;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  flex: 1;
  flex-grow: 1;
  box-sizing: content-box;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  width: 220px;
  min-width: 220px;

  background-color: #000000;
  color: white;

  font-size: 1rem;
  font-weight: 700;
}

.sidebar ul {
  width: 100%;
  padding-left: 0;
}

.sidebar li {
  width: 100%;
  height: 64px;
  display: table;
}

.sidebar li a {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 64px;
  line-height: 1.5em;

  padding-left: 60px;
  padding-right: 10px;

  box-sizing: border-box;
}

.sidebar a,
.sidebar a:active,
.sidebar a:visited {
  color: white;
  text-decoration: none;
}

.sidebar a:hover,
.sidebar li.selected a {
  background-color: #323245;
  color: #5d5fef;
}

.sidebar li a {
  position: relative;
}

.sidebar li a::before {
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: white;
  width: 28px;
  height: 28px;
  top: 18px;
  left: 16px;
  position: absolute;
  display: block;
  content: "";
}

.sidebar li a:hover::before,
.sidebar li.selected a::before {
  background-color: #5d5fef;
}

.account_settings::before {
  mask: url(../../components/images/icons/account_settings.svg);
}

.extension::before {
  mask: url(../../components/images/icons/extension.svg);
}

.file_check::before {
  mask: url(../../components/images/icons/file_check.svg);
}

.history::before {
  mask: url(../../components/images/icons/history.svg);
}

.organization::before {
  mask: url(../../components/images/icons/organization.svg);
}

.rule_settings::before {
  mask: url(../../components/images/icons/rule_settings.svg);
}

.sidebar .company_name {
  height: 76px;

  line-height: 76px;
  padding-left: 20px;
}

.sidebar .logout {
  position: absolute;
  bottom: 76px;
  width: 100%;

  height: 76px;

  line-height: 76px;
  padding-left: 32px;
}

.sidebar .logout button {
  color: lightgray;
}

.sidebar .service_name {
  position: absolute;
  bottom: 0;
  width: 100%;

  height: 76px;
  border-top: white solid 1px;

  line-height: 76px;
  padding-left: 32px;
}
